// ViewMedicionModal.js
import React from "react";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const baseUrl = process.env.REACT_APP_BASE_URL;
// Estilos del modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const ViewMedicionModal = ({ open, handleClose, medicion }) => {
  if (!medicion) return null; // Asegura que haya una medición seleccionada
  const foto = medicion.image ? `${baseUrl}/${medicion.image}` : null;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="view-medicion-modal-title"
      aria-describedby="view-medicion-modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography id="view-medicion-modal-title" variant="h6">
            Detalles de la Medición
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Medición:</strong> {medicion.medicion}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Lote:</strong> {medicion.Lote.lote}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Manzana:</strong> {medicion.Lote.manzana}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Fecha:</strong> {new Date(medicion.createdAt).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Abonado:</strong> {medicion.paid ? "Sí" : "No"}
            </Typography>
          </Grid>
          {foto && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={2}>
                <img
                  src={foto}
                  alt="Medición"
                  style={{ maxWidth: "100%", maxHeight: "300px", borderRadius: "8px" }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default ViewMedicionModal;
