// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    primary: {
      main: "#4A619E",
    },
    secondary: {
      main: "#8093D5",
    },
    info: {
      main: "#E8EFFF",
    },
    warning: {
      main: "#D3A518",
    },
    error: {
      main: "#E24E1B",
    },
  },
});

export default theme;
