import React, { useState, useContext, useEffect } from "react";
import { Drawer, List, ListItem, ListItemText, Divider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
// import UserContext from "../UserContext";
import { useNavContext } from "../NavContext";
const Sidebar = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  const { isSidebarOpen, toggleSidebar } = useNavContext();
  //   const { user } = useContext(UserContext);
  const handleListItemClick = (path) => {
    setSelectedItem(path);
  };
  const storedUser = localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  return (
    <Drawer open={isSidebarOpen} onClose={toggleSidebar} sx={{ paddingTop: "65px" }}>
      <List sx={{ paddingTop: "65px" }}>
        {user && user.role === "admin" && (
          <>
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/dashboard" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={user && user.role === "admin" ? "/dashboard" : "/lotesPro"}
              onClick={() =>
                handleListItemClick(user && user.role === "admin" ? "/dashboard" : "/lotesPro")
              }
            >
              <ListItemText primary="Panel de Control" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/lotes" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={user && user.role === "admin" ? "/lotes" : "/lotesPro"}
              onClick={() =>
                handleListItemClick(user && user.role === "admin" ? "/lotes" : "/lotesPro")
              }
            >
              <ListItemText primary="Lotes" />
            </ListItem>
            <Divider />

            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/personal" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={"/personal"}
              onClick={() => handleListItemClick("/personal")}
            >
              <ListItemText primary="Personal" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/propietariosAdmin" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={"/propietariosAdmin"}
              onClick={() => handleListItemClick("/propietariosAdmin")}
            >
              <ListItemText primary="Propietarios" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/areas" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={"/areas"}
              onClick={() => handleListItemClick("/areas")}
            >
              <ListItemText primary="Areas" />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/mediciones/all" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={"/mediciones/all"}
              onClick={() => handleListItemClick("/mediciones/all")}
            >
              <ListItemText primary="Mediciones" />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/comprobantes" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={"/comprobantes"}
              onClick={() => handleListItemClick("/comprobantes")}
            >
              <ListItemText primary="Comprobantes" />
            </ListItem>
            <Divider />
          </>
        )}
        {user && user.role === "propietario" && (
          <>
            <ListItem
              sx={{
                color: "inherit",
                bgcolor: selectedItem === "/lotes" ? "#e0e0e0" : "transparent",
                fontSize: "20px",
              }}
              component={Link}
              to={user && user.role === "admin" ? "/lotes" : "/lotesPro"}
              onClick={() =>
                handleListItemClick(user && user.role === "admin" ? "/lotes" : "/lotesPro")
              }
            >
              <ListItemText primary="Lotes" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/invitados" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/invitados"
              onClick={() => handleListItemClick("/invitados")}
            >
              <ListItemText primary="Invitados" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/entradasPorPropietario" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/entradasPorPropietario"
              onClick={() => handleListItemClick("/entradasPorPropietario")}
            >
              <ListItemText primary="Historial de Invitados" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/mediciones" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/mediciones"
              onClick={() => handleListItemClick("/mediciones")}
            >
              <ListItemText primary="Mediciones" />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/propietario/comprobantes" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/propietario/comprobantes"
              onClick={() => handleListItemClick("/propietario/comprobantes")}
            >
              <ListItemText primary="Comprobantes" />
            </ListItem>
            <Divider />
          </>
        )}
        {user && user.role === "portero" && (
          <>
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/propietarios" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/propietarios"
              onClick={() => handleListItemClick("/propietarios")}
            >
              <ListItemText primary="Propietarios" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/invitadosTotal" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/invitadosTotal"
              onClick={() => handleListItemClick("/invitadosTotal")}
            >
              <ListItemText primary="Historial" />
            </ListItem>
            <Divider />
          </>
        )}
        {user && user.role === "mantenimiento" && (
          <>
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/lotesMant" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/lotesMant"
              onClick={() => handleListItemClick("/lotesMant")}
            >
              <ListItemText primary="lotesMant" />
            </ListItem>
            <Divider />
            <ListItem
              sx={{
                fontSize: "20px",
                color: "inherit",
                bgcolor: selectedItem === "/mediciones/all" ? "#e0e0e0" : "transparent",
              }}
              component={Link}
              to="/mediciones/all"
              onClick={() => handleListItemClick("/mediciones/all")}
            >
              <ListItemText primary="Mediciones" />
            </ListItem>
            <Divider />
          </>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
