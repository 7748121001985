import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import UserContext from "../../UserContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ViewMedicionModal from "../admin/components/ViewMedicionModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
const MedicionesPage = () => {
  const { setUser, user } = useContext(UserContext);
  const [mediciones, setMediciones] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewOpen, setViewOpen] = useState(false);
  const [medicionToView, setMedicionToView] = useState(null);
  const storedUser = localStorage.getItem("user");

  useEffect(() => {
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
    }
    fetchMediciones();
  }, [storedUser]);

  const fetchMediciones = async () => {
    try {
      const response = await axiosInstance.get(`/api/mediciones`);
      setMediciones(response.data);
    } catch (error) {
      console.error("Error fetching mediciones:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openViewModal = (medicion) => {
    setMedicionToView(medicion);
    setViewOpen(true);
  };

  const handleCloseViewModal = () => {
    setViewOpen(false);
    setMedicionToView(null);
  };

  const paginatedMediciones = mediciones.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Historial de Mediciones</Typography>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Medición
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Lote
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Manzana
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Fecha
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Abonado
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedMediciones.map((medicion) => (
              <TableRow
                key={medicion.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {medicion.medicion}
                </TableCell>
                <TableCell align="left">{medicion.Lote.lote}</TableCell>
                <TableCell align="left">{medicion.Lote.manzana}</TableCell>
                <TableCell align="left">
                  {format(new Date(medicion.createdAt), "dd/MM/yyyy HH:mm:ss")}
                </TableCell>
                <TableCell align="left">
                  {medicion.paid ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </TableCell>{" "}
                {/* Celda para el icono */}
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={() => openViewModal(medicion)} // Abre el modal de visualización
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={mediciones.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>
      <ViewMedicionModal
        open={viewOpen}
        handleClose={handleCloseViewModal}
        medicion={medicionToView}
      />
    </Box>
  );
};

export default MedicionesPage;
