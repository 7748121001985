// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/admin/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import Lotes from "./pages/admin/Lotes";
import Invitados from "./pages/propietario/Invitados";
import InvitadosTotal from "./pages/portero/InvitadosTotal";
import Propietarios from "./pages/portero/Propietarios";
import InvitadoForm from "./components/InvitadoForm";
import FormSuccess from "./components/formSuccess";
import { UserProvider } from "./UserContext";
import { NavProvider } from "./NavContext";
import LotesPro from "./pages/propietario/Lotes";
import NotAuthorized from "./pages/auth/NotAuthorized";
import InvitadosPropietarios from "./pages/portero/InvitadosPropietarios";
import ValidateEntry from "./components/ValidateEntry";
import EntradasPorPropietario from "./pages/propietario/EntradasInvitados";
import Personal from "./pages/admin/Personal";
import PropietariosAdmin from "./pages/admin/Propietarios";
import Areas from "./pages/admin/Areas";
import PropietarioDetalles from "./pages/portero/PropietarioDetalles";
import Perfil from "./pages/propietario/Perfil";
import MedicionesPage from "./pages/propietario/Mediciones";
import Mediciones from "./pages/admin/Mediciones";
import MedicionesAll from "./pages/admin/MedicionesAll";
import LotesMant from "./pages/mantenimiento/Lotes";
import PanelControl from "./pages/admin/PanelControl";
import Comprobantes from "./pages/propietario/Comprobantes";
import ComprobantesAdmin from "./pages/admin/Comprobantes";
function App() {
  return (
    <NavProvider>
      <UserProvider>
        <Router>
          <Routes>
            <Route path="*" element={<NotAuthorized />} />
            // Global
            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/form/:token/:lote" element={<InvitadoForm />} />
            <Route path="/form-success" element={<FormSuccess />} />
            // Admin
            <Route
              path="/dashboard"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <PanelControl />
                    </Dashboard>
                  }
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="/lotes"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Lotes />
                    </Dashboard>
                  }
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="/personal"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Personal />
                    </Dashboard>
                  }
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="/propietariosAdmin"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <PropietariosAdmin />
                    </Dashboard>
                  }
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="/areas"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Areas />
                    </Dashboard>
                  }
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="/mediciones/:id"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Mediciones />
                    </Dashboard>
                  }
                  allowedRoles={["admin", "mantenimiento"]}
                />
              }
            />
            <Route
              path="/mediciones/all"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <MedicionesAll />
                    </Dashboard>
                  }
                  allowedRoles={["admin", "mantenimiento"]}
                />
              }
            />
            <Route
              path="/comprobantes"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <ComprobantesAdmin />
                    </Dashboard>
                  }
                  allowedRoles={["admin"]}
                />
              }
            />
            // Propietario
            <Route
              path="/lotesPro"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <LotesPro />
                    </Dashboard>
                  }
                  allowedRoles={["propietario"]}
                />
              }
            />
            <Route
              path="/credenciales"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Perfil />
                    </Dashboard>
                  }
                  allowedRoles={["propietario"]}
                />
              }
            />
            <Route
              path="/invitados"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Invitados />
                    </Dashboard>
                  }
                  allowedRoles={["admin", "propietario"]}
                />
              }
            />
            <Route
              path="/entradasPorPropietario"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <EntradasPorPropietario />
                    </Dashboard>
                  }
                  allowedRoles={["propietario"]}
                />
              }
            />
            <Route
              path="/mediciones"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <MedicionesPage />
                    </Dashboard>
                  }
                  allowedRoles={["propietario"]}
                />
              }
            />
            <Route
              path="/propietario/comprobantes"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <Comprobantes />
                    </Dashboard>
                  }
                  allowedRoles={["propietario"]}
                />
              }
            />
            // Portero
            <Route
              path="/invitadosByProps/:id"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <InvitadosPropietarios />
                    </Dashboard>
                  }
                  allowedRoles={["portero"]}
                />
              }
            />
            <Route
              path="/invitadosTotal"
              element={
                <PrivateRoute
                  element={
                    <Dashboard>
                      <InvitadosTotal />
                    </Dashboard>
                  }
                  allowedRoles={["portero", "admin"]}
                />
              }
            />
            <Route
              path="/propietarios"
              element={
                <PrivateRoute
                  allowedRoles={["portero"]}
                  element={
                    <Dashboard>
                      <Propietarios />
                    </Dashboard>
                  }
                />
              }
            />
            <Route
              path="/propietarios/:id"
              element={
                <PrivateRoute
                  allowedRoles={["portero"]}
                  element={
                    <Dashboard>
                      <PropietarioDetalles />
                    </Dashboard>
                  }
                />
              }
            />
            <Route
              path="/validateEntry/:token/:lote/:invitacionToken"
              element={
                <PrivateRoute allowedRoles={["admin", "portero"]} element={<ValidateEntry />} />
              }
            />
            // mantinimiento
            <Route
              path="/lotesMant"
              element={
                <PrivateRoute
                  allowedRoles={["mantenimiento"]}
                  element={
                    <Dashboard>
                      <LotesMant />
                    </Dashboard>
                  }
                />
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </NavProvider>
  );
}

export default App;
