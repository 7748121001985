import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance.js";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  MenuItem,
  Select,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../UserContext";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Personal = () => {
  const { setUser, user } = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [currentUser, setCurrentUser] = useState({
    username: "",
    password: "",
    email: "",
    cedula: "", // Añadir este estado para manejar la cédula
    telefono: "", // Añadir este estado para manejar la cédula
    foto: null,
  });
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    // Intenta obtener el usuario desde localStorage al cargar la aplicación
    const storedUser = localStorage.getItem("user");
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/auth/getAllMantenimiento`);
    setUsers(response.data);
  };

  const handleOpen = () => {
    setCurrentUser({
      username: "",
      password: "",
      email: "",
      cedula: "", // Añadir este estado para manejar la cédula
      telefono: "", // Añadir este estado para manejar la cédula
      foto: null,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //   const handleSave = async () => {
  //     try {
  //       if (currentUser.id) {
  //         await axiosInstance.put(`${baseUrl}/api/auth/update/${currentUser.id}`, currentUser);
  //       } else {
  //         await axiosInstance.post(`${baseUrl}/api/auth/register`, currentUser);
  //       }
  //       fetchUsers();
  //       handleClose();
  //       toast.success("Usuario guardado exitosamente");
  //     } catch (error) {
  //       toast.error(error.response.data.error);
  //     }
  //   };
  const validateForm = () => {
    const newErrors = {};

    if (!currentUser.username) {
      newErrors.username = "El nombre de usuario es obligatorio";
    }

    if (!currentUser.password) {
      newErrors.password = "La contraseña es obligatoria";
    } else if (currentUser.password.length < 6) {
      newErrors.password = "La contraseña debe tener al menos 6 caracteres";
    }

    if (!currentUser.email) {
      newErrors.email = "El correo electrónico es obligatorio";
    } else if (!/\S+@\S+\.\S+/.test(currentUser.email)) {
      newErrors.email = "El correo electrónico no es válido";
    }

    if (!currentUser.cedula) {
      newErrors.cedula = "La cédula es obligatoria";
    } else if (!/^\d+$/.test(currentUser.cedula)) {
      newErrors.cedula = "La cédula debe contener solo números";
    }

    if (!currentUser.telefono) {
      newErrors.telefono = "El teléfono es obligatorio";
    } else if (!/^\d+$/.test(currentUser.telefono)) {
      newErrors.telefono = "El teléfono debe contener solo números";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length !== 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      return toast.error("Formulario no válido");
    }
    const formData = new FormData();
    formData.append("username", currentUser.username);
    formData.append("password", currentUser.password);
    formData.append("email", currentUser.email);
    formData.append("role", "mantenimiento");
    formData.append("cedula", currentUser.cedula); // Añadir la cédula al FormData
    formData.append("telefono", currentUser.telefono); // Añadir la cédula al FormData
    formData.append("foto", currentUser.foto); // Añadir la foto al FormData

    try {
      if (!currentUser.id) {
        await axiosInstance.post(`${baseUrl}/api/auth/register`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Usuario agregado exitosamente");
      } else {
        await axiosInstance.put(`${baseUrl}/api/auth/update/${currentUser.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success("Usuario editado exitosamente");
      }
      console.log("Usuario agregado exitosamente");

      fetchUsers();
      handleClose();
    } catch (error) {
      handleClose();
      toast.error(error.response.data.error);

      console.error("Error al agregar usuario:", error);
    }
  };
  const handleEdit = (user) => {
    setCurrentUser(user);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/api/auth/delete/${userToDelete.id}`);
      fetchUsers();
      setConfirmOpen(false);
      toast.success("Usuario eliminado exitosamente");
    } catch (error) {
      toast.error("Error al eliminar el usuario");
    }
  };

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };
  const handleFileChange = (e) => {
    setCurrentUser({ ...currentUser, foto: e.target.files[0] });
  };
  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gestión de Personal</Typography>
        {user && user.role === "admin" && (
          <Box>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Agregar Personal
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Nombre de Usuario</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Correo Electrónico
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Rol
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {user.username}
                </TableCell>
                <TableCell align="right">{user.email}</TableCell>
                <TableCell align="right">{user.role}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEdit(user)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="warning" onClick={() => handleDeleteClick(user)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Diálogo para agregar o editar usuario */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentUser.id ? "Editar Personal" : "Agregar Personal"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Nombre de Usuario"
            type="text"
            fullWidth
            value={currentUser.username}
            onChange={handleNewUserChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            margin="dense"
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            value={currentUser.password}
            onChange={handleNewUserChange}
            error={!!errors.password}
            helperText={errors.password}
          />
          <TextField
            margin="dense"
            name="email"
            label="Correo Electrónico"
            type="email"
            fullWidth
            value={currentUser.email}
            onChange={handleNewUserChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="dense"
            name="cedula"
            label="Cédula"
            type="text"
            fullWidth
            value={currentUser.cedula}
            onChange={handleNewUserChange}
            error={!!errors.cedula}
            helperText={errors.cedula}
          />
          <TextField
            margin="dense"
            name="telefono"
            label="Telefono"
            type="text"
            fullWidth
            value={currentUser.telefono}
            onChange={handleNewUserChange}
            error={!!errors.telefono}
            helperText={errors.telefono}
          />

          <input
            type="file"
            name="foto"
            accept="image/*"
            onChange={handleFileChange} // Agregar esta línea para manejar el archivo
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de confirmación */}
      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>¿Estás seguro de que quieres eliminar a {userToDelete?.username}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Personal;
