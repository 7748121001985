// src/components/Dashboard.js
import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { Grid } from "@mui/material";

const Dashboard = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={12} zIndex={"modal"}>
        <Navbar />
      </Grid>
      <Grid item container>
        <Grid item md={2}>
          <Sidebar />
        </Grid>
        <Grid item md={12} sx={{ paddingTop: "65px" }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
