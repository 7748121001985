import React, { createContext, useContext, useState } from "react";

// Creamos el contexto
const NavContext = createContext();

// Hook personalizado para acceder al contexto
export const useNavContext = () => useContext(NavContext);

// Proveedor del contexto
export const NavProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <NavContext.Provider value={{ isSidebarOpen, toggleSidebar }}>{children}</NavContext.Provider>
  );
};
