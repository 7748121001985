import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Input,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomSwitch from "../../components/CustomSwitch";
import UserContext from "../../UserContext";

const baseUrl = process.env.REACT_APP_BASE_URL;

const LotesPro = () => {
  const { setUser, user } = useContext(UserContext);

  const [lotes, setLotes] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [currentLote, setCurrentLote] = useState({
    lote: "",
    manzana: "",
    estado: "habilitado",
    userId: "",
    sharedUserIds: [],
  });
  const [shareLote, setShareLote] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);
  const [newUserData, setNewUserData] = useState({
    username: "",
    password: "",
    email: "",
    role: "propietario",
  });

  useEffect(() => {
    fetchLotes();
    fetchUsers();
  }, []);

  useEffect(() => {
    // Intenta obtener el usuario desde localStorage al cargar la aplicación
    const storedUser = localStorage.getItem("user");
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
    }
  }, []);

  const fetchLotes = async () => {
    const response = await axiosInstance.get(
      user && user.role ? `${baseUrl}/api/lotes` : `${baseUrl}/api/lotes/getMyLotes`
    );
    setLotes(response.data);
  };

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/api/auth`);
    setUsers(response.data);
  };

  const handleOpen = () => {
    setCurrentLote({
      lote: "",
      manzana: "",
      estado: "habilitado",
      userId: "",
      sharedUserIds: [],
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShareOpen = (lote) => {
    setShareLote(lote);
    setShareOpen(true);
    setSelectedUsers([]);
  };

  const handleShareClose = () => {
    setShareOpen(false);
  };

  const handleSave = async () => {
    if (currentLote.id) {
      await axiosInstance.put(`${baseUrl}/api/lotes/update/${currentLote.id}`, currentLote);
    } else {
      await axiosInstance.post(`${baseUrl}/api/lotes/create`, currentLote);
    }
    fetchLotes();
    handleClose();
  };

  const handleEdit = (lote) => {
    setCurrentLote(lote);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    await axiosInstance.delete(`${baseUrl}/api/lotes/delete/${id}`);
    fetchLotes();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "sharedUserIds") {
      setCurrentLote({
        ...currentLote,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      setCurrentLote({ ...currentLote, [name]: value });
    }
  };

  const handleToggleEstado = async (lote) => {
    const updatedLote = {
      ...lote,
      estado: lote.estado === "habilitado" ? "deshabilitado" : "habilitado",
    };
    await axiosInstance.put(`${baseUrl}/api/lotes/updateEstado/${lote.id}`, updatedLote);
    fetchLotes();
  };

  const handleShare = async () => {
    await axiosInstance.post(`${baseUrl}/api/lotes/share`, {
      loteId: shareLote.id,
      userId: selectedUsers,
    });
    setShareOpen(false);
  };

  const handleAddUserDialogOpen = () => {
    setAddUserDialogOpen(true);
  };

  const handleAddUserDialogClose = () => {
    setAddUserDialogOpen(false);
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
  };

  const handleRoleChange = (e) => {
    setNewUserData({ ...newUserData, role: e.target.value });
  };

  const handleAddUser = async () => {
    try {
      await axiosInstance.post(`${baseUrl}/api/auth/register`, newUserData);
      // Lógica adicional después de agregar el usuario, como actualizar la lista de usuarios
      console.log("Usuario agregado exitosamente");
      setAddUserDialogOpen(false); // Cerrar el diálogo después de agregar el usuario
    } catch (error) {
      console.error("Error al agregar usuario:", error);
      // Manejar errores aquí según sea necesario
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gestión de Lotes</Typography>
        {user && user.role === "admin" && (
          <Box>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={{ mr: 2 }}>
              Agregar Lote
            </Button>
            <Button variant="contained" color="secondary" onClick={handleAddUserDialogOpen}>
              Agregar Usuario
            </Button>
          </Box>
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" gap={2} mt={3}>
        {lotes.map((lote) => (
          <Card key={lote.id} sx={{ minWidth: 275, maxWidth: 300 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {lote.lote}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manzana: {lote.manzana}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Estado: {lote.estado}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Propietario: {lote.Users.length > 0 ? lote.Users[0].username : "-"}
              </Typography>
            </CardContent>
            <CardActions>
              {/* {user && user.role === "admin" && (
                <IconButton color="primary" onClick={() => handleEdit(lote)}>
                  <EditIcon />
                </IconButton>
              )} */}
              {/* {user && user.role === "admin" && (
                <IconButton
                  color={lote.estado === "habilitado" ? "warning" : "success"}
                  onClick={() => handleToggleEstado(lote)}
                >
                  {lote.estado === "habilitado" ? <BlockIcon /> : <CheckCircleOutlineIcon />}
                </IconButton>
              )} */}
              <IconButton color="secondary" onClick={() => handleShareOpen(lote)}>
                <ShareIcon />
              </IconButton>
              {/* {user && user.role === "admin" && (
                <IconButton color="warning" onClick={() => handleDelete(lote.id)}>
                  <DeleteIcon />
                </IconButton>
              )} */}
            </CardActions>
          </Card>
        ))}
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentLote.id ? "Editar Lote" : "Agregar Lote"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="lote"
            label="Lote"
            type="text"
            fullWidth
            value={currentLote.lote}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="manzana"
            label="Manzana"
            type="text"
            fullWidth
            value={currentLote.manzana}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="share-user-select-label">Seleccionar Usuario</InputLabel>
            <Select
              labelId="share-user-select-label"
              value={currentLote.userId}
              onChange={(e) => setCurrentLote({ ...currentLote, userId: e.target.value })}
              input={<Input />}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={shareOpen} onClose={handleShareClose}>
        <DialogTitle>Compartir Lote</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="share-user-select-label">Seleccionar Usuario</InputLabel>
            <Select
              labelId="share-user-select-label"
              multiple
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.target.value)}
              input={<Input />}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShareClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleShare} color="primary">
            Compartir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addUserDialogOpen} onClose={handleAddUserDialogClose}>
        <DialogTitle>Agregar Nuevo Usuario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Nombre de Usuario"
            type="text"
            fullWidth
            value={newUserData.username}
            onChange={handleNewUserChange}
          />
          <TextField
            margin="dense"
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            value={newUserData.password}
            onChange={handleNewUserChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Correo Electrónico"
            type="email"
            fullWidth
            value={newUserData.email}
            onChange={handleNewUserChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="role-select-label">Rol</InputLabel>
            <Select
              labelId="role-select-label"
              name="role"
              value={newUserData.role}
              onChange={handleRoleChange}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="propietario">Propietario</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddUserDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddUser} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LotesPro;
