import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "qrcode.react"; // Importar QRCode
import UserContext from "../../UserContext"; // Importar UserContext

const baseUrl = process.env.REACT_APP_BASE_URL;

const Perfil = () => {
  const { user } = useContext(UserContext); // Obtener el usuario del contexto
  const [propietario, setPropietario] = useState(null);
  const [areas, setAreas] = useState([]);
  const [userAreas, setUserAreas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPropietario = async () => {
      try {
        const response = await axiosInstance.get(`${baseUrl}/api/auth/propietario/${user.id}`);
        setPropietario(response.data);
      } catch (error) {
        console.error("Error fetching propietario:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAreas = async () => {
      const response = await axiosInstance.get(`${baseUrl}/api/areas`);
      setAreas(response.data);
    };

    const fetchUserAreas = async () => {
      const response = await axiosInstance.get(`${baseUrl}/api/areas/userareas/${user.id}`);
      setUserAreas(response.data);
    };

    if (user) {
      fetchPropietario();
      fetchAreas();
      fetchUserAreas();
    }
  }, [user]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!propietario) {
    return <Typography variant="h6">Propietario no encontrado</Typography>;
  }

  const fotoUrl = propietario.foto ? `${baseUrl}/${propietario.foto}` : null;

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Detalles del Propietario
      </Typography>
      <Card sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
        <CardMedia sx={{ height: 140, backgroundColor: "#f5f5f5" }} title="Profile Picture">
          {fotoUrl ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <img src={fotoUrl} alt="Profile" style={{ maxHeight: "100%", maxWidth: "100%" }} />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <AccountCircleIcon sx={{ fontSize: 80, color: "#9e9e9e" }} />
            </Box>
          )}
        </CardMedia>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" component="div">
                {propietario.username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                <strong>Email:</strong> {propietario.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                <strong>Role:</strong> {propietario.role}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                <strong>Cédula:</strong> {propietario.cedula}
              </Typography>
            </Grid>
            {propietario.UserLotes.map((userLote, index) => (
              <Grid item xs={12} key={index}>
                <Typography variant="body1" color="textSecondary">
                  <strong>Lote:</strong> {userLote.Lote.lote}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  <strong>Manzana:</strong> {userLote.Lote.manzana}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Box mt={4}>
            <Typography variant="h6" gutterBottom>
              Permisos de Áreas
            </Typography>
            <List>
              {areas.map((area) => {
                const userArea = userAreas.find((ua) => ua.AreaId === area.id);
                return (
                  <ListItem key={area.id}>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {area.nombre}
                    </Typography>
                    {userArea?.Allow ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box mt={4} display="flex" justifyContent="center">
            <QRCode value={user.id.toString()} size={150} /> {/* Código QR */}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Perfil;
