// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element, allowedRoles }) => {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("userRole").replace(/"/g, "").trim();
  if (!token) {
    <Navigate to="/" />;
  }

  const cleanedUserRole = userRole.trim();

  return token && cleanedUserRole && allowedRoles.includes(cleanedUserRole) ? (
    element
  ) : (
    <Navigate to="/not-authorized" replace />
  );
};

export default PrivateRoute;
