import React, { useEffect, useState, useContext } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import UserContext from "../../UserContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateMedicionModal from "./components/CreateMedicionModal";
import EditMedicionModal from "./components/EditMedicionModal";
import DeleteMedicionModal from "./components/DeleteMedicionModal";
import ViewMedicionModal from "./components/ViewMedicionModal";
import VisibilityIcon from "@mui/icons-material/Visibility";

const Mediciones = () => {
  const [viewOpen, setViewOpen] = useState(false);
  const [medicionToView, setMedicionToView] = useState(null);
  const { setUser, user } = useContext(UserContext);
  const [mediciones, setMediciones] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id: loteId } = useParams();
  const [lotes, setLotes] = useState([]);
  const [lote, setLote] = useState(null); // Cambiado a null para mayor claridad
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [medicionToDelete, setMedicionToDelete] = useState(null);

  const [editOpen, setEditOpen] = useState(false);
  const [medicionToEdit, setMedicionToEdit] = useState(null);

  const [createOpen, setCreateOpen] = useState(false);

  const storedUser = localStorage.getItem("user");

  useEffect(() => {
    if (storedUser !== null && typeof storedUser !== "object") {
      setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
    }
    fetchMediciones();
    fetchLotes();
    fetchLoteById();
  }, [storedUser]);

  const fetchMediciones = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/mediciones/lote/${loteId}`);
      setMediciones(response.data);
    } catch (error) {
      console.error("Error fetching mediciones:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedMediciones = mediciones.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const fetchLotes = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/lotes`);
      setLotes(response.data);
    } catch (error) {
      console.error("Error fetching lotes:", error);
    }
  };
  const fetchLoteById = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/lotes/${loteId}`);
      setLote(response.data);
    } catch (error) {
      console.error("Error fetching lotes:", error);
    }
  };

  const handleCreate = () => {
    setCreateOpen(true); // Abre el modal
  };

  const handleModalClose = () => {
    setCreateOpen(false); // Cierra el modal
  };

  const openViewModal = (medicion) => {
    setMedicionToView(medicion);
    setViewOpen(true);
  };

  const handleCloseViewModal = () => {
    setViewOpen(false);
    setMedicionToView(null);
  };

  const handleCreateMedicion = async (newMedicion) => {
    try {
      const formData = new FormData();
      formData.append("medicion", newMedicion.medicion);
      formData.append("LoteId", newMedicion.LoteId);
      formData.append("paid", newMedicion.paid);

      // Agregar la imagen si existe
      if (newMedicion.image) {
        const imageBlob = await fetch(newMedicion.image).then((res) => res.blob()); // Convertir la URL de la imagen a un Blob
        formData.append("image", imageBlob, "captured-image.jpg"); // Nombre del archivo
      }

      await axiosInstance.post("/api/mediciones", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Importante para el manejo de archivos
        },
      });

      fetchMediciones(); // Refresca las mediciones después de crear una nueva
    } catch (error) {
      console.error("Error creating medicion:", error);
    }
  };

  const openEditModal = (medicion) => {
    setMedicionToEdit(medicion);
    setEditOpen(true);
  };

  const handleCloseModal = () => {
    setEditOpen(false);
    setMedicionToEdit(null);
  };

  const handleSave = (updatedMedicion) => {
    handleEditMedicion(updatedMedicion);
  };

  const handleEditMedicion = async (medicion) => {
    try {
      await axiosInstance.put(`/api/mediciones/${medicion.id}`, medicion);
      fetchMediciones();
    } catch (error) {
      console.error("Error al actualizar la medición:", error);
    }
  };

  const openDeleteModal = (medicion) => {
    setMedicionToDelete(medicion);
    setDeleteOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteOpen(false);
    setMedicionToDelete(null);
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/api/mediciones/${id}`);
      fetchMediciones(); // Refresca las mediciones después de eliminar
      handleCloseDeleteModal(); // Cierra el modal
    } catch (error) {
      console.error("Error al eliminar la medición:", error);
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      const today = format(new Date(), "dd/MM/yyyy");
      const response = await axiosInstance.get(`/api/mediciones/getTemplate/${loteId}`, {
        responseType: "blob", // Importante para manejar la descarga de archivos
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Consumo de agua - ${today}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error descargando la plantilla:", error);
    }
  };
  //   console.log("LOTE", lote);
  //   console.log("LOTES", lotes);

  return (
    <Box sx={{ padding: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">
          Historial de Mediciones FML: 143 - {lote && lote.lote.manzana} - {lote && lote.lote.lote}
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadTemplate}
            sx={{ mr: 2 }}
          >
            Descargar Plantilla
          </Button>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Agregar Medición
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Medición
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Lote
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Manzana
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Fecha
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Abonado
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedMediciones.map((medicion) => (
              <TableRow
                key={medicion.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {medicion.medicion}
                </TableCell>
                <TableCell align="left">{medicion.Lote.lote}</TableCell>
                <TableCell align="left">{medicion.Lote.manzana}</TableCell>
                <TableCell align="left">
                  {format(new Date(medicion.createdAt), "dd/MM/yyyy HH:mm:ss")}
                </TableCell>
                <TableCell align="left">
                  {medicion.paid ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    color="primary"
                    onClick={() => openViewModal(medicion)} // Abre el modal de visualización
                  >
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => openEditModal(medicion)}
                    key={medicion.id}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => openDeleteModal(medicion)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={mediciones.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>

      <CreateMedicionModal
        open={createOpen}
        handleClose={handleModalClose}
        lotes={lote ? lote : lotes}
        handleCreateMedicion={handleCreateMedicion}
      />

      <EditMedicionModal
        open={editOpen}
        handleClose={handleCloseModal}
        lotes={lotes}
        handleSave={handleSave}
        medicionToEdit={medicionToEdit}
      />

      <DeleteMedicionModal
        open={deleteOpen}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
        medicionToDelete={medicionToDelete}
      />

      <ViewMedicionModal
        open={viewOpen}
        handleClose={handleCloseViewModal}
        medicion={medicionToView}
      />
    </Box>
  );
};

export default Mediciones;
