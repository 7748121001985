import React, { createContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  //   useEffect(() => {
  //     // Intenta obtener el usuario desde localStorage al cargar la aplicación
  //     const storedUser = localStorage.getItem("user");
  //     if (storedUser !== null && typeof storedUser !== "object") {
  //       setUser(JSON.parse(storedUser)); // Parsea el usuario almacenado en localStorage si no es un objeto
  //     }
  //   }, []);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserContext;
