import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Areas = () => {
  const [areas, setAreas] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [currentArea, setCurrentArea] = useState({
    nombre: "",
    descripcion: "",
  });
  const [areaToDelete, setAreaToDelete] = useState(null);

  useEffect(() => {
    fetchAreas();
  }, []);

  const fetchAreas = async () => {
    try {
      const response = await axiosInstance.get(`${baseUrl}/api/areas`);
      setAreas(response.data);
    } catch (error) {
      toast.error("Error al obtener las áreas");
    }
  };

  const handleOpen = () => {
    setCurrentArea({ nombre: "", descripcion: "" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      if (currentArea.id) {
        await axiosInstance.put(`${baseUrl}/api/areas/${currentArea.id}`, currentArea);
      } else {
        await axiosInstance.post(`${baseUrl}/api/areas`, currentArea);
      }
      fetchAreas();
      handleClose();
      toast.success("Área guardada exitosamente");
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const handleEdit = (area) => {
    setCurrentArea(area);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`${baseUrl}/api/areas/${areaToDelete.id}`);
      fetchAreas();
      setConfirmOpen(false);
      toast.success("Área eliminada exitosamente");
    } catch (error) {
      toast.error("Error al eliminar el área");
    }
  };

  const handleDeleteClick = (area) => {
    setAreaToDelete(area);
    setConfirmOpen(true);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentArea({ ...currentArea, [name]: value });
  };

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">Gestión de Áreas</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Agregar Área
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }}>Nombre</TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Descripción
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="right">
                Acciones
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {areas.map((area) => (
              <TableRow key={area.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {area.nombre}
                </TableCell>
                <TableCell align="right">{area.descripcion}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEdit(area)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="warning" onClick={() => handleDeleteClick(area)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentArea.id ? "Editar Área" : "Agregar Área"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="nombre"
            label="Nombre"
            type="text"
            fullWidth
            value={currentArea.nombre}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="descripcion"
            label="Descripción"
            type="text"
            fullWidth
            value={currentArea.descripcion}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSave} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmOpen} onClose={handleConfirmClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que quieres eliminar el área {areaToDelete?.nombre}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Areas;
