import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const DeleteMedicionModal = ({ open, handleClose, handleDelete, medicionToDelete }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 400, 
        bgcolor: 'background.paper', 
        border: '2px solid #000', 
        boxShadow: 24, 
        p: 4 
      }}>
        <Typography variant="h6" component="h2">
          Confirmar Eliminación
        </Typography>
        <Typography sx={{ mt: 2 }}>
          ¿Estás seguro de que quieres eliminar la medición de {medicionToDelete?.Lote?.lote} {medicionToDelete?.Lote?.manzana}?
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="error" onClick={() => handleDelete(medicionToDelete.id)}>
            Eliminar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteMedicionModal;
