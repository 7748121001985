import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const NotAuthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate(-1); // Navegar hacia atrás
  };

  const handleGoHome = () => {
    navigate("/"); // Navegar a la página principal
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      bgcolor="#f5f5f5"
      padding={3}
    >
      <Typography variant="h4" color="error" gutterBottom>
        {location.state && location.state.error ? location.state.error : "No Autorizado"}
      </Typography>
      {!location.state ||
        (!location.state.error && (
          <Typography variant="body1" color="textSecondary" gutterBottom>
            No tienes permisos para acceder a esta página.
          </Typography>
        ))}
      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={handleGoBack} sx={{ mr: 2 }}>
          Volver
        </Button>
        <Button variant="contained" color="secondary" onClick={handleGoHome}>
          Ir a la Página Principal
        </Button>
      </Box>
    </Box>
  );
};

export default NotAuthorized;
